<template>
  <div class="box">
    <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm" size="small">
      <el-form-item label="标题" prop="title">
        <el-input v-model="info.title" placeholder="请输入标题"></el-input>
      </el-form-item>
      <el-form-item label="类型" prop="type">
        <el-select v-model="info.type" clearable placeholder="请选择类型" size="small">
          <el-option
              v-for="item in typeList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="图片" prop="pic">
        <SelectImgs :selectNum="1" :selectData="pic" :selectFunc="picChoose"></SelectImgs>
        <span class="" v-if="info.type==1">750*326px</span>
        <span class="" v-if="info.type==2">200*200px</span>
        <span class="" v-if="info.type==3">345*150px</span>
      </el-form-item>
      <el-form-item label="跳转类型" prop="jump_type">
        <el-select v-model="info.jump_type" clearable placeholder="请选择类型" size="small">
          <el-option
              v-for="item in jumpTypeList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="选择商品" v-if="info.jump_type==1">
        <el-button type="primary" @click="openGoods()">选择</el-button>
        <span class="mg-lf-10" v-if="info.jump_content.goods_id">{{info.jump_content.goods_title}}</span>
      </el-form-item>
      <el-form-item label="选择类目" v-if="info.jump_type==2">
        <el-select v-model="info.jump_content.menu_id" clearable placeholder="请选择分类" size="small" :filterable="true">
          <el-option
              v-for="item in menuList"
              :key="item.id"
              :label="item.title"
              :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="选择车型" v-if="info.jump_type==3">
        <el-select v-model="info.jump_content.car_id" clearable placeholder="请选择车型" size="small" :filterable="true">
          <el-option
              v-for="item in carModelList"
              :key="item.id"
              :label="item.title"
              :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="排序" prop="ordering">
        <el-input v-model="info.ordering" placeholder="请输入排序"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
        <el-button type="button" @click="reBack()">返回</el-button>
      </el-form-item>
    </el-form>
    <el-dialog
        title="选择商品"
        :visible.sync="dialogPayVisible"
        :destroy-on-close="true"
        width="90%">
      <goods-list @selectGoods="selectGoods"></goods-list>
    </el-dialog>
  </div>
</template>
<script>

import SelectImgs from '@/components/SelectImgs'
import goodsList from "@/components/goodsList";
export default {
  data() {
    return {
      pic: [],
      dialogPayVisible: false,
      city: [],
      info: {
        id: 0,
        pic: '',
        title: '',
        type: '',
        ordering: '',
        jump_type: '',
        jump_content: {
          goods_id: '',
          goods_title: '',
          menu_id: '',
        },
      },
      menuList: [],
      carModelList: [],
      source_domain: this.config.SOURCE_DOMAIN,
      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请选择类型', trigger: 'blur' }
        ]
      },
      typeList: [
        {id: 1, title: '首页banner1'},
        {id: 2, title: '首页小图'},
        {id: 3, title: '首页中图'},
        {id: 4, title: '首页banner2'},
      ],
      jumpTypeList: [
        {id: 1, title: '商品详情'},
        {id: 2, title: '商品类目'},
        // {id: 3, title: '车型'},
      ]
    };
  },
  created() {
    this.getMenuList()
    this.getCarModelList()
    if(this.$route.query.id) {
      this.info.id = this.$route.query.id
      this.getInfo()
    }
  },
  components: {
    SelectImgs,
    goodsList
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    openGoods() {
      this.dialogPayVisible = true
    },
    selectGoods(data) {
      this.info.jump_content.goods_id = data.id
      this.info.jump_content.goods_title = data.title
      this.dialogPayVisible = false
    },
    picChoose(data) {
      this.pic = [data.pic]
      this.info.pic = data.pic
    },
    getMenuList() {
      this.$api.goods.menuAll({}, res => {
        if (res.errcode == 0) {
          this.menuList = res.data
        }
      })
    },
    getCarModelList() {
      this.$api.merchant.cartypeIndex({page_size: 1000},res=>{
        if(res.errcode==0) {
          this.carModelList = res.data.data
        }
      })
    },
    getInfo() {
      var that = this
      this.$api.merchant.bannerDetail({id: that.info.id}, function (res) {
        if(res.errcode == 0) {
          that.info = res.data
          if(that.info.pic) {
            that.pic = [that.info.pic]
          }
          if(that.info.jump_type==0) {
            that.info.jump_type = ''
          }
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    submitForm(formName) {
      var that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var param = that.info
          if(this.info.id == 0) {
            this.$api.merchant.bannerAdd(param,function (res) {
              if(res.errcode == 0) {
                that.success(res.errmsg)
                that.reBack()
              } else {
                that.fail(res.errmsg)
              }
            })
          } else {
            this.$api.merchant.bannerEdit(param,function (res) {
              if(res.errcode == 0) {
                that.success(res.errmsg)
                that.reBack()
              } else {
                that.fail(res.errmsg)
              }
            })
          }
        } else {
          return false;
        }
      });
    },
  }
};
</script>
<style scoped>
  .box {
    background-color: #fff;
    padding: 20px;
    border-radius: 6px;
    min-height: 500px;
  }
</style>
